import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: "/index",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: ()=> import('@/pages/Index.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Index.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: "/about/menu",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/about/Menu.vue'),
    meta: {},
    name: "aboutMenu"
  },
  {
    path: "/about/tjup",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/about/TJUP.vue'),
    meta: {},
    name: "aboutTJUP"
  },
  {
    path: "/appshell",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Appshell.vue'),
    meta: {},
    name: "appshell"
  },
  {
    path: "/book/all",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/All.vue'),
    meta: {},
    name: "bookAll"
  },
  {
    path: "/book/catalogs",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/Catalogs.vue'),
    meta: {},
    name: "bookCatalogs"
  },
  {
    path: "/book/category",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/Category.vue'),
    meta: {},
    name: "bookCategory"
  },
  {
    path: "/book/detail",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/Detail.vue'),
    meta: {},
    name: "bookDetail"
  },
  {
    path: "/book/details/:id",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/details/_id.vue'),
    meta: {},
    name: "bookDetailsId"
  },
  {
    path: "/book/magnifer",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/Magnifer.vue'),
    meta: {},
    name: "bookMagnifer"
  },
  {
    path: "/book/menu",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/Menu.vue'),
    meta: {},
    name: "bookMenu"
  },
  {
    path: "/book/video",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/book/Video.vue'),
    meta: {},
    name: "bookVideo"
  },
  {
    path: "/contact",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Contact.vue'),
    meta: {},
    name: "contact"
  }, 
  {
    path: "/jobs",
     component: () => import(/* webpackChunkName: "home" */ '@/pages/Jobs.vue'),
    meta: {},
    name: "jobs"
  },
  {
    path: "/links",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Links.vue'),
    meta: {},
    name: "links"
  },
  {
    path: "/news/all",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/news/All.vue'),
    meta: {},
    name: "newsAll"
  },
  {
    path: "/news/detail/:id",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/news/detail/_id.vue'),
    meta: {},
    name: "newsDetailId"
  },
  {
    path: "/search",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Search.vue'),
    meta: {},
    name: "search"
  },
  {
    path: "/wechat",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Wechat.vue'),
    meta: {},
    name: "wechat"
  },
  {
    path: "/error",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Error.vue'),
    meta: {},
    name: "error",
  },
  {
    path: "/admin",
    component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/Index.vue'),
    meta: {},
    name: "admin",
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router