
import { createStore } from 'vuex'

import pageTransition from './modules/pageTransition'
import scrollBehavior from './modules/scrollBehavior'

const store = createStore({
  state () {
    return {
      count: 0,
    }
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  actions: {},
  modules: {
     pageTransition,
     scrollBehavior
  }
})

export default store