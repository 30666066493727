<template>
  <div id="app">
    <router-view class="router-view"> 
    </router-view>
  </div>
</template>


<script>
import {  nextTick } from 'vue'
 
const ENABLE_SCROLL_CLASS = "app-view-scroll-enabled";

export default {
  name: "app",
  components: {
    
  },
  computed: { 
    pageTransitionType() {
      return this.$store.state.type; 
    },

    pageTransitionEffect() {
      return this.$store.state.effect;
    },

    pageTransitionClass() {
      return `transition-${this.pageTransitionType}`;
    },

    scrollPostionMap() {
       return this.$store.state.scrollPostionMap;
    },

    // https://github.com/lavas-project/lavas/issues/119
    routerViewKey() {
      let { name, params } = this.$route;
      let paramKeys = Object.keys(params);
      if (paramKeys.length) {
        return name + paramKeys.reduce((prev, cur) => prev + params[cur], "");
      }
      return null;
    },
  },
  data() {
    return {
      // https://github.com/lavas-project/lavas/issues/112
    };
  },
  methods: {
   
    /**
     * make current page container scrollable,
     * and restore its scroll position.
     */
    restoreContainerScrollPosition(containerEl, scrollTop) {
      containerEl.classList.add(ENABLE_SCROLL_CLASS);
      containerEl.scrollTop = scrollTop;
    },

    /**
     * make body scrollable,
     * and restore its scroll position.
     */
    restoreBodyScrollPosition(containerEl, scrollTop) {
      containerEl.classList.remove(ENABLE_SCROLL_CLASS);
      document.body.scrollTop = document.documentElement.scrollTop = scrollTop;
    },

    handleBeforeEnter(el) {
      let pageId = el.dataset.pageId;
      let { y: scrollTop = 0 } = this.scrollPostionMap[pageId] || {};
      nextTick(() => {
        this.restoreContainerScrollPosition(el, scrollTop);
      });
    },

    handleAfterEnter(el) {
      let pageId = el.dataset.pageId;
      let { y: scrollTop = 0 } = this.scrollPostionMap[pageId] || {};
      this.restoreBodyScrollPosition(el, scrollTop);
    }
  }
};
</script>

<style lang="stylus">
$page-transition-duration = 0.35s;

#app {
  font-family:  'SourceSansPro-Regular','SourceSansPro-SemiBold', 'SourceSansPro-Bold', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;

  @font-face {
    font-family: 'SourceSansPro-Regular'; 
    src: url('./assets/fonts/SourceSansPro-Regular.ttf') format("truetype"); 
    font-weight: normal;
    font-style: normal;
  }

 @font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url('./assets/fonts/SourceSansPro-Semibold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
  }

   @font-face {
    font-family: 'SourceSansPro-Bold';
    src: url('./assets/fonts/SourceSansPro-Bold.ttf') format("truetype"); 
    font-weight: bold;
    font-style: normal;
  }
  .app-view {
    // position absolute
    // top 0
    // right 0
    // bottom 0
    // left 0
    -webkit-overflow-scrolling: touch;
    background: white;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &.transition-slide {
      transition: transform $page-transition-duration cubic-bezier(0, 0, 0.2, 1);

      &.slide-left-enter {
        transform: translate(100%, 0);
      }

      &.slide-left-enter-active {
        box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
      }

      &.slide-right-enter {
        transform: translate(-30%, 0);
        transition-timing-function: linear;
      }

      &.slide-right-leave-active {
        transform: translate(100%, 0);
        box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
        z-index: 99;
      }

      &.slide-left-leave-active {
        transform: translate(-30%, 0);
        transition-timing-function: linear;
      }

      &.app-view-scroll-enabled, &.slide-left-enter-active, &.slide-left-leave-active, &.slide-right-enter-active, &.slide-right-leave-active {
        overflow-y: auto;
      }
    }

    &.transition-fade {
      opacity: 1;
      transition: opacity 1s ease;

      &.fade-enter {
        opacity: 0;
      }

      &.fade-leave-active {
        opacity: 0;
      }
    }
  }
}
</style>